
import { defineComponent, computed, ref, onMounted } from 'vue'
import store from '@/store'

export default defineComponent({
  name: 'Video',
  data () {
    return {
      TextBible: {}
    }
  },
  methods: {
    openNav () {
      document.getElementById('mySidenavVideo').style.width = '250px'
    },
    closeNav () {
      document.getElementById('mySidenavVideo').style.width = '0'
    }
  },
  async mounted () {
    const response = await fetch('https://blv-vue3-tp.firebaseio.com/bible.json')
    const data = await response.json()

    const arrayVerse = Object.keys(data).map(key => {
      return { ...data[key], id: key }
    })

    this.TextBible = arrayVerse[Math.floor(Math.random() * arrayVerse.length)]
  },
  setup () {
    const idList = ref('UUSb71yKJmS0eHyhRRl00ioQ')
    const showModal = ref(false)
    const videoModal = ref('')
    const videoModalTitle = ref('')
    const ListVideoData = computed(() => store.state.ListVideoData)

    const isPrivateVideo = (item: any) => {
      return (
        item.snippet.title === 'Private video' ||
        item.snippet.description.includes('This video is private.')
      )
    }

    const filteredVideoList = computed(() => {
      return ListVideoData.value.filter((item) => !isPrivateVideo(item))
    })

    const defaultVideoId = '2bfP6fhg5Dw'

    const videoModalComputed = computed(() => {
      if (videoModal.value) {
        return videoModal.value
      }
      const firstPublicVideo = ListVideoData.value.find((item) => !isPrivateVideo(item))
      return firstPublicVideo ? firstPublicVideo.snippet.resourceId.videoId : defaultVideoId
    })
    console.log('ListVideoData.value>>', ListVideoData.value)

    const listVideo = (value: string) => {
      idList.value = value
      const payload = { value }
      store.dispatch('getListVideoData', payload)
    }

    const countVideo = () => {
      store.commit('AddVideoOnPage', {
        count: 5
      })
      listVideo(idList.value)

      setTimeout(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth' // Добавление плавного скроллинга
        })
      }, 100) // Таймаут, чтобы дать время DOM обновиться
    }

    const clikItemVideo = async (item: any) => {
      videoModal.value = item.snippet.resourceId.videoId
      console.log('>>>', videoModal.value)
      videoModalTitle.value = item.snippet.title
      window.scrollTo({ top: 300, behavior: 'smooth' })
    }

    const startListVideo = () => {
      videoModal.value = ''
      store.dispatch('getListVideoData')
    }
    onMounted(startListVideo)

    return {
      ListVideoData,
      listVideo,
      showModal,
      videoModalTitle,
      videoModal: videoModalComputed,
      filteredVideoList,
      isPrivateVideo,
      countVideo,
      clikItemVideo
    }
  }
})
